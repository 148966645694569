import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { iconMenu } from 'src/app/shared/utils/icon-menu';
import { NotificacoesService } from 'src/app/services/notificacoes/notificacoes.service';
import { WhiteLabelService } from 'src/app/services/whitelabel/whitelabel.service';

import { EditCompanyModalComponent } from './edit-company/edit-company-modal.component';

interface Notification {
  id: number;
  descricao: string;
  dataCriacao?: Date;
  tipo: number;
  ordemServico: string;
  lido: boolean;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {

  @Input() currentRout = '';
  title = '';
  hoveredBar = false;
  menuList = iconMenu;
  menuOpen: boolean = false;
  userMenuOpen: boolean = false;
  isLoadingUpload: boolean = false;
  isEditingName: boolean = false; // Modo de edição do nome

  drop = {
    notification: [] as Notification[],
    open: false
  };

  user = JSON.parse(atob(sessionStorage['user']));
  userCustom = JSON.parse(atob(sessionStorage['user-custom']));

  @ViewChild('dropdownNotification') dropdownNotification!: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private notificacoesService: NotificacoesService,
    private whiteLabelService: WhiteLabelService,
    private router: Router,
    private renderer: Renderer2,
    private modal: NgbModal,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      const target = e.target as HTMLElement;

      if (this.menuOpen && this.dropdownNotification && !this.dropdownNotification.nativeElement.contains(target)) {
        this.menuOpen = false;
        this.changeDetector.detectChanges();
      }

      if (this.userMenuOpen && target && !target.closest('.box-user') && !this.isEditingName) {
        this.userMenuOpen = false;
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnInit() {
    this.getNotificationUsers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.title = this.menuList.find((item: any) => item.path == this.currentRout)?.title || '';
    }
  }

  toggleNotification() {
    this.menuOpen = !this.menuOpen;
  }

  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }

  async getNotificationUsers() {
    try {
      this.notificacoesService.post(`/GetNewNotificacoes`, { MatrizEmail: this.user.email, Pessoa: [0], Limit: 10 }).subscribe(
        res => {
          this.drop = {
            notification: res,
            open: false,
          };
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  goToNotification(notification: Notification) {
    if (notification.tipo === 1) {
      this.router.navigate(['/ordem-de-servico/'], { queryParams: { os: notification.ordemServico } });
    } else {
      this.router.navigate(['/ordem-de-servico/'], { queryParams: { os: notification.ordemServico, chat: true } });
    }
  }

  setAsRead(id: number, i: any) {
    this.drop.notification[i].lido = true;
    this.changeDetector.detectChanges();
    this.notificacoesService.put(`/UpdateStatusLido/${id}`, { id }).subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  seeAll() {
    this.drop.notification.forEach((notificacao) => {
      notificacao.lido = true;
      this.notificacoesService.put(`/UpdateStatusLido/${notificacao.id}`, { id: notificacao.id }).subscribe(() => {
        this.changeDetector.detectChanges();
      });
    });
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('login');
  }

  hasUnreadNotifications(): boolean {
    return this.drop.notification?.some(notification => !notification.lido);
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append('file', file);
      this.isLoadingUpload = true;

      this.whiteLabelService.post('/files-images', formData).subscribe({
        next: (res) => {
          this.userCustom.image = { id: res.id, baseUrl: res.baseUrl, file: res.file };
          this.updateUserProfile();
        },
        error: (err) => {
          console.error('Erro ao fazer upload da imagem:', err);
          this.isLoadingUpload = false;
        }
      });
    } else {
      alert('Por favor, selecione uma imagem válida.');
    }
  }

  toggleEditName(event: Event) {
    event.stopPropagation();
    this.isEditingName = !this.isEditingName;
  }

  saveName(event: Event) {
    event.stopPropagation();
    this.updateUserProfile();
  }

  updateUserProfile() {
    const updatedUserData = {
      name: this.userCustom.name,
      thumb: this.userCustom.image?.id
    };

    this.isLoadingUpload = true;

    this.whiteLabelService.patch(`/user/${this.userCustom.id}`, updatedUserData).subscribe({
      next: (res) => {
        sessionStorage.setItem('user-custom', btoa(JSON.stringify(res)));
        this.userCustom = res;
        this.isEditingName = false;
        this.isLoadingUpload = false;
        this.userMenuOpen = false;
        this.changeDetector.detectChanges();
      },
      error: (err) => {
        console.error('Erro ao atualizar o perfil do usuário:', err);
        this.isLoadingUpload = false;
      }
    });
  }

  openModalCompany(): void {
    const modalRef = this.modal.open(EditCompanyModalComponent, { centered: true, size: 'lg' });

    // if (modalRef.componentInstance instanceof EditCompanyModalComponent) {
    //   modalRef.componentInstance.search.subscribe((outputData: any) => {
    //   });
    // }
  }
}
